/**
 * class Fr
 *
 * In dieser Klasse sind alle Übersetzungestexte für Deutsch hinterlegt.
 */
class Fr {
  constructor () {
    this.label_Umfang = 'Nombre de pages'
    this.label_seitig = 'pages'
    this.label_Format = 'Format'
    this.label_Ausrichtung = 'Orientation de la page'
    this.label_Hochformat = 'Portrait/Format long'
    this.label_Querformat = 'Paysage/Format oblong'
    this.label_Papier = 'Papier'
    this.label_ungestrichen = 'non couché'
    this.label_gestrichen = 'couché'
    this.label_Auflage = 'Tirage'
    this.label_Pages = 'Pages'
    this.label_Seitenaufdruck = 'Impression des pages'
    this.label_Einseitig = 'Recto'
    this.label_Doppelseitig = 'Recto/Verso'
    this.label_colorBackSide = 'Couleurs au verso'
    this.help_colorFrontBack = 'Le recto est toujours imprimé en quadrichromie. Pour le verso, il est possible de choisir entre la quadrichromie et le noir/blanc.'
    this.label_blackWhite = 'Noir/Blanc'
    this.label_color = 'En quadrichromie'
    this.label_calculate = 'Calculer...'
    this.label_fold = 'Type de pliage'
    this.label_fold_f06_1 = 'Pli roulé'
    this.label_fold_f06_5 = 'Pli zickzack ou Leporello'
  }
}

export default Fr
