import React, { Component } from 'react'
import Legacy from './Legacy'
import Layout from '../components/layout'
import './Info.scss'

export default class info extends Component {

  constructor (props) {
    super(props)
    this.state = {
      src: '/uStore/Images/_eigene_Skins/pureprint_shop/info-page/Anleitung_de.html'
    }
    let deFound = window.location.href.indexOf('de-DE')
    let frFound = window.location.href.indexOf('fr-FR')
    if (frFound > -1) {
      this.state.src = '/uStore/Images/_eigene_Skins/pureprint_shop/info-page/Anleitung_fr.html'
    } else if (deFound > -1) {
      this.state.src = '/uStore/Images/_eigene_Skins/pureprint_shop/info-page/Anleitung_de.html'
    } else {
      this.state.src = '/uStore/Images/_eigene_Skins/pureprint_shop/info-page/Anleitung_de.html'
    }
  }

  render () {
    return <Layout {...this.props} className='category'>
      <div id='infoPage'>
        <iframe src={this.state.src} title="Info" className='w100 h100'></iframe>
      </div>
    </Layout>
  }
}
