import De from './De'
import Fr from './Fr'

class T {

  constructor (location) {
    this.location = location;
    this.currentCulture = '';
    this.setCulture();
  }

  existsInUrl(searchValue) {
    let itExistsInUrl = false;
    let re = new RegExp(searchValue);
    let result = re.exec(this.location.href);
    if (result !== undefined && result !== null && result.length > 0) {
      itExistsInUrl = true;
    }
    return itExistsInUrl;
  }

  setCulture() {
    let tempCulture = '';
    if (this.existsInUrl('de-DE') || this.existsInUrl('de-CH')) {
      tempCulture = 'de-DE';
    } else if (this.existsInUrl('fr-FR')) {
      tempCulture = 'fr-FR';
    }
    if (tempCulture !== this.currentCulture) {
      this.currentCulture = tempCulture;

      switch (tempCulture) {
        case 'fr-FR':
          this.translation = new Fr();
          break;
        default:
          this.translation = new De();
          break;
      }
    }
  }

  get() {
    this.setCulture();
    return this.translation;
  }
}

export default T;
