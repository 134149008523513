/**
 * class De
 *
 * In dieser Klasse sind alle Übersetzungestexte für Deutsch hinterlegt.
 */
class De {
  constructor () {
    this.label_Umfang = 'Umfang'
    this.label_seitig = 'seitig'
    this.label_Format = 'Format'
    this.label_Ausrichtung = 'Ausrichtung'
    this.label_Hochformat = 'Hochformat'
    this.label_Querformat = 'Querformat'
    this.label_Papier = 'Papier'
    this.label_ungestrichen = 'ungestrichen'
    this.label_gestrichen = 'gestrichen'
    this.label_Auflage = 'Auflage'
    this.label_Pages = 'Pages'
    this.label_Seitenaufdruck = 'Seitenaufdruck'
    this.label_Einseitig = 'Einseitig'
    this.label_Doppelseitig = 'Doppelseitig'
    this.label_colorBackSide = 'Farbigkeit Rückseite'
    this.help_colorFrontBack = 'Die Vorderseiten werden immer farbig gedruckt. Für die Rückseiten kann zwischen farbig und schwarz-weiss gewählt werden.'
    this.label_blackWhite = 'Schwarz/Weiss'
    this.label_color = 'Farbig'
    this.label_calculate = 'berechnen...'
    this.label_fold = 'Falzart'
    this.label_fold_f06_1 = 'Wickelfalz'
    this.label_fold_f06_5 = 'Zickzackfalz'
  }
}

export default De
